* {
  font-family: "Noto Sans Display", sans-serif;
}

@font-face {
  font-family: "WorkSans Black";
  src: url("../Fonts/WorkSans-Black.woff") format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "WorkSans Bold";
  src: url("../Fonts/WorkSans-Bold.woff2") format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "WorkSans Regular";
  src: url("../Fonts/WorkSans-Regular.woff2") format("woff2");
  font-style: normal;
}
.TitleRestuaran{

  font-weight: 800;
}
.MainLogo {
  width: 100%;
  height: 35vh;
  background-size: cover;
}
.DescriptionRestuaran{

line-height: 23px;
}
#RestuaranTitle{
  text-align: center;
  position: absolute;
  top: 40%;
  width: 80%;
  left: 10%;
  right: 10%;
  color: #fff;
  font-size: 45px;
}
#AliposTitle{
  font-family: 'WorkSans Regular';
  color: #fff;
  font-size: 25px;
}
.InfoRestuaran {
  width: 98%;
  border: 1px solid transparent;
  margin: auto;
  padding: 3%;
  margin-top: 10px;
  border-radius: 25px;
  color: #353535;
}
h3,
h2 {
  font-family: "Noto Sans Display", sans-serif;
  font-weight: 900;
}

.CatalogMenu {
  width: 45px;
  height: 45px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #e2e2e2;
  position: relative;
  left: 3%;
}
.BtnCatigory {
  padding: 13px 20px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}
.BtnCatigory.active {
  background-color: #9A27FF;
  color: #fff;
}


.BtnCatigory a {
  color: black;
}
.LinksBtn{
  color: #000;
}
.Menu {
  position: sticky;
  top: -1px;
  background-color: #fff;
  padding: 12px 0;
  z-index: 100;
}
.css-sfcr95 {
  margin-bottom: 15px;
}
.articles:nth-child(1) {
  top: -50px;
}
.articles {
  position: relative;
  top: -40px;
}

.Section h3 {
  padding: 15px 0;
  margin-left: 4%;
  font-size: 28px;
}
.CatigoryButtons button:hover {
  background-color: rgb(189, 189, 189);
}
.CatigoryButtons a {
  text-decoration: none;
  color: #000;
}
.CatigoryButtons:nth-child(1) {
  border-top: 0.5px solid #ccc;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  overflow: hidden;
}
.drawerOpen {
  position: fixed;
  left: calc(98% / 2);
  overflow: hidden;
}
.css-fy2uxz {
  overflow: hidden !important;
}
.imgInfoOpen {
  width: 100%;

}
.imgInfoOpen img {
  width: 100%;
  height: 100% ;
  object-fit: contain;

}
.OpenInfoText {
  padding: 4% 2.5%;
}
.OpenInfoText h3 {
  line-height: 40px;
}
@media screen and (max-width: 710px) {
  .css-1qla6w2 {
    padding: 1.5% 1.5% 9% !important;
  }
  #RestuaranTitle{
    font-size: 35px;
  }
}
@media screen and (max-width: 620px) {
  .css-1qla6w2 {
    padding: 1.5% 1.5% 10% !important;
  }
  
  .css-7qq23p svg {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 550px) {
  .css-1qla6w2 {
    width: 48% !important;
  }
  .ShareBtnt{
    width: 43px !important;
    height: 43px !important;
  }
  #RestuaranTitle{
    font-size: 28px;
  }
  .articles h3 {
    font-size: 22px;
  }
  .iconify,
  .iconify--ion {
    font-size: 20px !important;
  }
  .InfoRestuaran h2 {
    font-size: 22px !important;
  }
  .InfoRestuaran h3 {
    font-size: 17px !important;
  }
  .css-1qla6w2 {
    padding: 1.5% 1.5% 8% !important;
  }
  .BtnCatigory {
    font-size: 13px !important;
    padding: 11.5px 17px !important;
  }
  .CatalogMenu {
    width: 40px;
    height: 40px;
  }
  .css-7qq23p {
    width: 44px !important;
    height: 44px !important;
  }
  .css-7qq23p svg {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 430px) {
  .css-1qla6w2 {
    padding: 1.5% 1.5% 9% !important;
  }
  .BtnCatigory {
    font-size: 12px !important;
  }
  .CatalogMenu {
    width: 38px;
    height: 38px;
  }
  .iconify,
  .iconify--ion {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 380px) {
  .css-1qla6w2 {
    padding: 1.5% 1.5% 10% !important;
  }
}
@media screen and (max-width: 350px) {
  .css-1qla6w2 {
    padding: 1.5% 1.5% 9% !important;
  }
}
.last-article{
  padding-bottom: 25%;
}

@media screen and (max-width:547px) {
  .BoxCardProduct{
    gap: 3% !important;
    justify-content: space-between !important;
    object-fit: 1000px;
  }
}